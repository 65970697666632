import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
};

export const getAlbumsByDate = (startDate: string, endDate: string) =>
  axios({
    url: 'https://api.stg.apmmusic.com/search/albums',
    method: 'POST',
    data: {
      limit: '1000',
      offset: 0,
      sort: 'explore',
      si: 511,
      rdate: [
        startDate, 
        endDate
      ],
    },
    headers,
  });