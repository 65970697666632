import { Flex, Text, Box } from '@contentful/f36-components';

import { NewReleasesType } from '../MainComponent/types';
import EditorsListItem from './components/EditorsListItem';
import useDropFn, { onDropParams } from 'hooks/useDropFn';

type EditorsTypes = {
  editors: NewReleasesType['editors'];
  allowedDropEffect: string;
  onDropItem: (albumCode: string) => void;
  onDeleteItem?: (albumCode: string) => void;
};

const EditorsList = ({ editors, allowedDropEffect, onDropItem, onDeleteItem }: EditorsTypes) => {
  const handleDropItem = ({ albumCode }: onDropParams) => {
    onDropItem(albumCode);
  };

  const { dropRef, containerStyles } = useDropFn({
    allowedDropEffect,
    type: 'editors',
    onDrop: handleDropItem,
  });

  return (
    <Box marginTop="spacingL">
      <Text fontSize="fontSizeL">Editor's Picks</Text>
      <div
        ref={dropRef}
        style={{ ...containerStyles, marginTop: 8, height: 250, overflowY: 'scroll' }}
      >
        <Flex flexDirection="column" padding="spacingXs">
          {editors.map(editor => (
            <EditorsListItem key={editor} value={editor} onDelete={onDeleteItem} />
          ))}
        </Flex>
      </div>
    </Box>
  );
};

export default EditorsList;
