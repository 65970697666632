import { useEffect } from 'react';
import { PlainClientAPI } from 'contentful-management';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import MainComponent from './MainComponent';

interface FieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const Field = (props: FieldProps) => {
  // If you only want to extend Contentful's default editing experience
  // reuse Contentful's editor components
  // -> https://www.contentful.com/developers/docs/extensibility/field-editors/

  useEffect(() => {
    props.sdk.window.startAutoResizer();
  }, [props.sdk.window]);

  return (
    <DndProvider backend={HTML5Backend}>
      <MainComponent sdk={props.sdk} />
    </DndProvider>
  );
};

export default Field;
