import { useDrop } from 'react-dnd';

export type onDropParams = {
  albumCode: string;
};

export type DropFnProps = {
  allowedDropEffect: string;
  type: string;
  onDrop: (value: onDropParams) => void;
};

const createBorderAndShadowStyle = (isActive: boolean, canDrop: boolean) => {
  if (isActive) {
    return { border: '1px solid #009688', boxShadow: '3px 3px 17px 8px rgba(175,175,175,0.52)' };
  } else if (canDrop) {
    return { border: '1px solid #0099FF', boxShadow: '3px 3px 17px 8px rgba(175,175,175,0.52)' };
  }

  return { border: '1px solid #CCCCCC' };
};

const useDropFn = ({ allowedDropEffect, type, onDrop }: DropFnProps) => {
  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: 'box',
      type,
      drop: onDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [allowedDropEffect],
  );

  const isActive = canDrop && isOver;
  const containerStyles = createBorderAndShadowStyle(isActive, canDrop);

  return { isActive, containerStyles, dropRef };
};

export default useDropFn;
