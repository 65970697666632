import { Flex, Text } from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';

type EditorsListItemProps = {
  value: string;
  onDelete?: (value: string) => void;
};

const EditorsListItem = ({ value, onDelete }: EditorsListItemProps) => {
  return (
    <Flex marginTop="spacingXs">
      <Text fontSize="fontSizeL">{value}</Text>
      <button
        onClick={() => onDelete?.(value)}
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <DeleteIcon style={{ cursor: 'pointer' }} variant="muted" />
      </button>
    </Flex>
  );
};

export default EditorsListItem;
