import { Box, Flex, Text, Stack, Button } from '@contentful/f36-components';
import { useEffect, useState, useCallback } from 'react';

import { getAlbumsByDate } from 'utils/api';
import { MainComponentProps, NewReleasesType } from './types';
import AlbumsList from '../AlbumsList';
import ReleasesList from '../ReleasesList';
import EditorsList from '../EditorsList';
import { AlbumTypes } from '../AlbumsList/components/AlbumListItem';

const MainComponent = ({ sdk }: MainComponentProps) => {
  const [newReleases, setNewReleases] = useState<NewReleasesType>({ editors: [], releases: [] });
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [albums, setAlbums] = useState<AlbumTypes[] | []>([]);
  const [isLoading, setIsLoading] = useState<undefined | boolean>();
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [successSaved, setIsSuccessSaved] = useState<boolean>(false);
  const [errorSaved, setIsErrorSaved] = useState<boolean>(false);

  const fetchGetAlbumsByDate = useCallback(async () => {
    try {
      setIsLoading(true);
      const albumsResult = await getAlbumsByDate(startDate, endDate);
      setAlbums(albumsResult.data.rows);
    } catch (error) {
      setAlbums([]);
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!startDate || !endDate) return;

    const startDateInMiliseconds = Date.parse(startDate);
    const endDateInMiliseconds = Date.parse(endDate);

    if (
      startDateInMiliseconds > 0 &&
      endDateInMiliseconds > 0 &&
      endDateInMiliseconds > startDateInMiliseconds
    ) {
      fetchGetAlbumsByDate();
    }
  }, [endDate, fetchGetAlbumsByDate, startDate]);

  useEffect(() => {
    const newReleases = sdk.field.getValue();
    newReleases && setNewReleases(newReleases);
  }, [sdk.field]);

  useEffect(() => {
    if (successSaved) {
      setTimeout(() => {
        setIsSuccessSaved(false);
      }, 2500);
    }
  }, [successSaved]);

  useEffect(() => {
    if (errorSaved) {
      setTimeout(() => {
        setIsErrorSaved(false);
      }, 2500);
    }
  }, [errorSaved]);

  const handleEditorsDropItem = (albumCode: string) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => {
      const currentEditors = prevState.editors;
      if (currentEditors.indexOf(albumCode) === -1) {
        currentEditors.push(albumCode);
        // setAlbums((prevAlbumsState: AlbumTypes[]) => prevAlbumsState.filter((album) => album.albumCode !== albumCode));
      }
      return { ...prevState, editors: currentEditors };
    });
  };

  const handleReleasesDropItem = (albumCode: string) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => {
      const currentReleases = prevState.releases;
      if (currentReleases.indexOf(albumCode) === -1) {
        currentReleases.push(albumCode);
        // setAlbums((prevAlbumsState: AlbumTypes[]) => prevAlbumsState.filter((album) => album.albumCode !== albumCode));
      }
      return { ...prevState, releases: currentReleases };
    });
  };

  const handleDeleteEditorsItem = (albumCode: string) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => ({
      ...prevState,
      editors: prevState.editors.filter((value: string) => value !== albumCode),
    }));
  };

  const handleDeleteReleasesItem = (albumCode: string) => {
    setIsSaveDisabled(false);
    setNewReleases((prevState: NewReleasesType) => ({
      ...prevState,
      releases: prevState.releases.filter((value: string) => value !== albumCode),
    }));
  };

  const handleSaveClick = async () => {
    try {
      setIsSaveDisabled(true);
      setIsErrorSaved(false);
      setIsSuccessSaved(false);
      
      await sdk.field.setValue(newReleases);
      
      setIsSaveDisabled(false);
      setIsSuccessSaved(true);
    } catch (error) {
      setIsErrorSaved(true);
      setIsSaveDisabled(false);
    }
  };

  return (
    <div>
      <Text fontSize="fontSizeL" marginTop="spacingL">
        Matching Albums
      </Text>
      <Stack marginTop="spacingM" marginBottom="spacingM">
        <Box marginRight="spacingM">
          <Flex flexDirection="column">
            <Text>From</Text>
            <input type="date" onChange={({ target: { value } }) => setStartDate(value)} />
          </Flex>
        </Box>
        <Box>
          <Flex flexDirection="column">
            <Text>To</Text>
            <input type="date" onChange={({ target: { value } }) => setEndDate(value)} />
          </Flex>
        </Box>
      </Stack>
      <Box>
        {isLoading ? <Text>Loading...</Text> : undefined}
        {isLoading === false && albums.length === 0 && <Text>No matching albums found</Text>}
      </Box>
      <Flex flexDirection="row">
        <AlbumsList albums={albums} />
        <Flex style={{ width: '40%' }} flexDirection="column">
          <ReleasesList
            releases={newReleases.releases}
            allowedDropEffect="move"
            onDropItem={handleReleasesDropItem}
            onDeleteItem={handleDeleteReleasesItem}
          />
          <EditorsList
            editors={newReleases.editors}
            allowedDropEffect="move"
            onDropItem={handleEditorsDropItem}
            onDeleteItem={handleDeleteEditorsItem}
          />
        </Flex>
      </Flex>
      <Box marginTop="spacingL">
        <Button variant="primary" onClick={handleSaveClick} isDisabled={isSaveDisabled}>
          Save
        </Button>
      </Box>
      <Box marginTop="spacingM">
        {successSaved && <Text>Saved</Text>}
        {errorSaved && <Text>Error</Text>}
      </Box>
    </div>
  );
};

export default MainComponent;
