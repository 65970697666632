import { Flex, Text, TextLink } from '@contentful/f36-components';

import useDragFn from 'hooks/useDragFn';

export type AlbumTypes = {
  id: string;
  akcd: string;
  title: string;
  albumCode: string;
};

interface AlbumListItemProps extends AlbumTypes {
  onDragItemEnd?: (albumCode: string) => any;
}

const AlbumsListItem = ({ id, akcd, title, albumCode }: AlbumListItemProps) => {
  const { dragRef, opacity } = useDragFn({ albumCode });

  return (
    <div ref={dragRef} style={{ opacity, cursor: 'grab' }}>
      <Flex key={id} marginTop="spacingXs">
        <Text>{`${albumCode} ${title}`}</Text> 
        <TextLink href={`https://beta.apmmusic.com/albums/${id}`} target="_blank">
          (Album URL)
        </TextLink>
      </Flex>
    </div>
  );
};

export default AlbumsListItem;
