import { useDrag } from 'react-dnd';

type UseDragProps = {
  albumCode: string;
  onDragEnd?: (albumCode: string) => any;
};

const useDragFn = ({ albumCode, onDragEnd }: UseDragProps) => {
  const [{ opacity }, dragRef] = useDrag(() => ({
    type: 'box',
    item: { albumCode },
    end(item, monitor) {
      const dropResult: any = monitor.getDropResult();
      if (item && dropResult) {
        onDragEnd?.(item.albumCode);
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  return { opacity, dragRef };
};

export default useDragFn;
