import { memo } from 'react';
import { Text, Flex, Box } from '@contentful/f36-components';

import useDropFn, { onDropParams } from 'hooks/useDropFn';
import { NewReleasesType } from '../MainComponent/types';
import ReleasesListItem from './components/ReleasesListItem';

type ReleasesTypes = {
  releases: NewReleasesType['releases'];
  allowedDropEffect: string;
  onDropItem: (albumCode: string) => void;
  onDeleteItem?: (albumCode: string) => void;
};

const ReleasesList = ({ releases, allowedDropEffect, onDropItem, onDeleteItem }: ReleasesTypes) => {
  const handleDropItem = ({ albumCode }: onDropParams) => {
    onDropItem(albumCode);
  };

  const { dropRef, containerStyles } = useDropFn({
    allowedDropEffect,
    type: 'releases',
    onDrop: handleDropItem,
  });

  return (
    <Box flexDirection="column">
      <Text fontSize="fontSizeL">Other New Releases</Text>
      <div
        ref={dropRef}
        style={{ ...containerStyles, marginTop: 8, height: 250, overflowY: 'scroll' }}
      >
        <Flex flexDirection="column" padding="spacingXs">
          {releases.map(release => (
            <ReleasesListItem key={release} value={release} onDelete={onDeleteItem} />
          ))}
        </Flex>
      </div>
    </Box>
  );
};

export default memo(ReleasesList);
