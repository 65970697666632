import { memo, useEffect, useState } from 'react';
import { Pagination } from '@contentful/f36-pagination';
import { Box } from '@contentful/f36-components';

import AlbumsListItem, { AlbumTypes } from './components/AlbumListItem';

type AlbumListProps = {
  albums: AlbumTypes[];
};

const itemsPerPage = 20;

const AlbumsList = ({ albums }: AlbumListProps) => {
  const [page, setPage] = useState(0);
  const [activeAlbums, setActiveAlbums] = useState<AlbumTypes[] | []>([]);

  useEffect(() => {
    if (!activeAlbums.length) {
      setActiveAlbums(albums.slice(0, itemsPerPage));
    }
  }, [activeAlbums.length, albums]);

  useEffect(() => {
    setActiveAlbums(
      page > 0
        ? albums.slice(page * itemsPerPage, (page + 1) * itemsPerPage)
        : albums.slice(0, itemsPerPage),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albums]);

  const handlePageChange = (page: number) => {
    setPage(page);
    setActiveAlbums(
      page > 0
        ? albums.slice(page * itemsPerPage, (page + 1) * itemsPerPage)
        : albums.slice(0, itemsPerPage),
    );
  };

  return (
    <Box paddingRight="spacingM" style={{ width: '60%' }}>
      {activeAlbums.map((album: AlbumTypes) => (
        <AlbumsListItem {...album} key={album.title} />
      ))}
      {albums.length ? (
        <Box marginTop="spacingM">
          <Pagination
            activePage={page}
            onPageChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            totalItems={albums.length}
          />
        </Box>
      ) : undefined}
    </Box>
  );
};

export default memo(AlbumsList);
